.container-full-content {
  background-color: var(--color-primary-400);
  color: var(--color-secondary-100);
  box-shadow: var(--color-secondary-100) 0px 1px 2px 0px,
    var(--color-secondary-100) 0px 1px 2px 0px;
  position: relative;
}

.container-full-content::after {
  content: "";
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: -4px;
  background-color: var(--color-primary-400);
}

.container-partial-content {
  padding: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container-image {
  width: calc(9.7em);
  height: calc(3.65em);
  margin-bottom: 1em;
  position: relative;
}

.container-image a {
  position: absolute;
  inset: 0;
}

.container-image a:focus {
  position: absolute;
  outline-offset: 0.65em;
}

.container-image a:focus::after {
  inset: -0.65em;
}

.image {
  object-fit: contain;
  cursor: pointer;
}

.copyright {
  font-size: 1rem;
}
.company-info {
  font-size: 1rem;
}

/* Content */

.container-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6.5em;
}

.content-column-list {
  list-style: none;
}

.content-column-list-element {
  margin-top: 1em;
}

.content-column-list-element a {
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-secondary-blue);
  text-decoration: none;
  position: relative;
  transition-property: color;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
}

.content-column-list-element a:hover {
  color: var(--color-accent-400) !important;
}

.content-column-list-element a:hover::before {
  content: "";
  background-color: var(--color-accent-400);
  position: absolute;
  inset: 100% 0 -2px 0;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
  animation-name: anchor-underline;
  animation-duration: 150ms;
}

@keyframes anchor-underline {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}

@media (max-width: 900px) {
  .container-partial-content {
    flex-direction: column;
  }

  .container-content {
    margin-top: 3.5em;
    gap: 3.5em;
  }
}

@media (max-width: 300px) {
  .container-content {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 400px) {
  .container-content {
    grid-template-columns: repeat(2, 1fr);
  }
}
