/* set monsterat font */
* {
  font-family: "montserrat", sans-serif;
  color: var(--color-primary-400);
}

html,
body,
#__next {
  min-height: 100%;
  background-color: var(--color-dark-400);
}

#__next {
  overflow: hidden;
}

:root {
  --color-accent-100: #724b5e33;
  --color-accent-400: #d7b162;
  --color-dark-100: #7b98b1;
  --color-dark-400: #000000;
  --color-light-100: #ffffff;
  --color-light-400: rgba(40, 41, 43, 0.32);

  --color-blue-dark: #0d3772;
  --color-blue-light: #305486;
  --color-gold-light: #d7b162;

  --color-primary-100: var(--color-dark-100);
  --color-primary-400: var(--color-dark-400);
  --color-secondary-100: var(--color-light-100);
  --color-secondary-400: var(--color-light-400);

  --partial-spacing: 5em;

  --border-size: 0.2rem;
  --border-radius-large: 0.5rem;
  --border-radius-small: 0.25em;

  --shadow-color: 227deg 36% 15%;
  --shadow-card: 0px 0.6px 0.7px hsl(var(--shadow-color) / 0.13),
    0px 2.7px 3.3px -0.2px hsl(var(--shadow-color) / 0.15),
    0px 4.6px 5.7px -0.4px hsl(var(--shadow-color) / 0.16),
    0px 6.9px 8.5px -0.7px hsl(var(--shadow-color) / 0.17),
    0px 9.9px 12.3px -0.9px hsl(var(--shadow-color) / 0.18),
    0px 14.1px 17.4px -1.1px hsl(var(--shadow-color) / 0.2),
    -0.1px 19.9px 24.6px -1.3px hsl(var(--shadow-color) / 0.21),
    -0.1px 27.8px 34.4px -1.5px hsl(var(--shadow-color) / 0.22),
    -0.1px 38.2px 47.3px -1.7px hsl(var(--shadow-color) / 0.23);

  --transition-duration: 150ms;
  --transition-timing-function-elastic: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

* {
  margin: 0;
  padding: 0;
  /* font-family: "Roboto Slab", serif; */
  color: var(--color-secondary-100);
  box-sizing: border-box;
}

.container-full {
  width: 100%;
  display: flex;
  justify-content: center;
}

.container-partial {
  max-width: calc(68rem + 2 * 2.5rem);
  width: 100%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  box-sizing: border-box;
}

h1 {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 4.15rem;
}

h2 {
  font-size: 1.85rem;
  font-weight: 600;
  line-height: 2.85rem;
}

h3 {
  font-size: 1.35rem;
  font-weight: 500;
  line-height: 2.15rem;
}

h4 {
  font-size: 1.1rem;
  font-weight: 600;
}

h5 {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.025rem;
}

p,
li {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 2rem;
}

ul {
  padding: 0;
}

a {
  color: var(--color-accent-400);
  font-weight: 600;
}

:focus:not(:focus-visible) {
  outline: none !important;
}

:focus:not(:focus-visible)::after {
  content: none !important;
}

a:focus {
  border-radius: var(--border-radius-small);
  outline-offset: 0.35em;
  outline: var(--border-size) solid var(--color-accent-400);
  position: relative;
  z-index: 1;
}

a:focus::after {
  content: "";
  border-radius: var(--border-radius-small);
  position: absolute;
  inset: -0.35em;
  background-color: var(--color-accent-400);
  opacity: 0.25;
  z-index: -1;
}

a:active {
  opacity: 0.5;
}

strong {
  font-weight: 600;
}

small {
  margin-top: 0.65em;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1rem;
  display: block;
}

.checkbox-hidden {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}

@media (max-width: 850px) {
  .container-partial {
    max-width: calc(65rem + 2 * 1.5em);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  h1 {
    font-size: 2.65rem !important;
    line-height: 3.35rem !important;
  }

  h2 {
    font-size: 1.65rem;
    line-height: 2.65rem;
  }

  h3 {
    font-size: 1.15rem;
    line-height: 1.85rem;
  }

  h4 {
    font-size: 1rem;
  }

  h5 {
    font-size: 0.85rem;
    line-height: 1.5rem;
    letter-spacing: 0.025rem;
  }

  p,
  li {
    font-size: 1rem;
    line-height: 1.85rem;
  }
}

@media (max-width: 400px) {
  h1 {
    font-size: 2.35rem !important;
    line-height: 3rem !important;
  }
}
